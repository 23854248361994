import React from 'react';
import { IoChevronForwardCircle } from "react-icons/io5";

const CourseSection = ({ section, topics, isActive, activeTopic, onClick, onTopicClick }) => {
  return (
    <div className="course_section">
      <div 
        className={`course_section_header ${isActive ? 'course_active' : ''}`}
        onClick={onClick}
      >
        <IoChevronForwardCircle className={`section_icon ${isActive ? 'rotated' : ''}`} />
        <span>{section}</span>
      </div>
      {isActive && (
        <div className="course_topics">
          {topics.map((topic, index) => (
            <div 
              key={index} 
              className={`course_topic ${activeTopic === topic ? 'topic_active' : ''}`}
              onClick={() => onTopicClick(topic)}
            >
              <span className="topic_dot"></span>
              {topic.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseSection;