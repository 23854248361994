// CourseSlides.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { X, BookOpen, GraduationCap,ChevronRight } from 'lucide-react';
import SlideControls from './SlideControls';
import './Slides.css';
import Header from "../Header/Header"

const SlideProgress = ({ current, total }) => (
  <div className="progress-container">
    <div className="progress-bar" style={{ width: `${(current + 1) / total * 100}%` }} />
  </div>
);

const CourseSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { course } = useParams();
  const navigate = useNavigate();

  const slideContent = {
    'python-basics': [
      {
        type: 'intro',
        title: "Let's study Python Basics!",
        description: "In this lesson, you'll learn the fundamentals of Python programming, focusing on Variables and Data Types. We'll guide you step by step, so follow along closely until the end!",
        image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-DrpkswLcBM4glmjFbHl0H3yf7j2B5w.png"
      },
      {
        type: 'overview',
        title: "Variables & Data Types in Python",
        description: "Python uses variables to store data in memory. Understanding different data types is crucial for effective programming. We'll cover String, Numeric, and Boolean variables, as well as Type Conversion.",
        image: "/python-variables.png"
      },
      {
        title: "String Variables",
        description: "Strings are used to represent text data in Python. They are created by enclosing characters in quotes.",
        code: 'name = "Alice"\ngreeting = \'Hello, \' + name\nprint(greeting)',
        output: "Hello, Alice",
        explanation: "We created two string variables and concatenated them using the + operator."
      },
      {
        title: "Numeric Variables",
        description: "Python has two main types of numeric variables: integers (whole numbers) .",
        code: 'age = 25\nheight = 1.75\nprint(f"Age: {age}, Height: {height} meters")',
        output: "Age: 25, Height: 1.75 meters",
        explanation: "We used an integer for age and a float for height, then printed them using an f-string."
      },
      {
        title: "Boolean Variables",
        description: "Boolean variables can only have two values: True or False. They're often used in conditional statements.",
        code: 'is_student = True\nis_employed = False\nprint(f"Student: {is_student}, Employed: {is_employed}")',
        output: "Student: True, Employed: False",
        explanation: "We created two boolean variables and printed their values."
      },
      {
        title: "Type Conversion",
        description: "Sometimes you need to convert data from one type to another. Python provides built-in functions for this.",
        code: 'num_str = "42"\nnum_int = int(num_str)\nnum_float = float(num_str)\nprint(f"String: {num_str}, Integer: {num_int}, Float: {num_float}")',
        output: "String: 42, Integer: 42, Float: 42.0",
        explanation: "We converted a string to both an integer and a float using int() and float() functions."
      },
      {
        type: 'outro',
        title: "Great job!",
        description: "You've learned the basics of Variables and Data Types in Python. Now, let's practice what you've learned!"
      }
    ]
  };

  const content = slideContent[course] || [];

  const handlePrevious = () => {
    setCurrentSlide(prev => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentSlide(prev => Math.min(content.length - 1, prev + 1));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') handleNext();
    if (e.key === 'ArrowLeft') handlePrevious();
    if (e.key === 'Enter' && currentSlide === content.length - 1) {
      navigate('/compiler');
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentSlide]);

  const handleClose = () => {
    navigate('/course');
  };

  const renderFirstSlide = () => (
    <div className="first-slide">
      <GraduationCap size={38} className="slide-icon" />
      <h1 className="main-title">{content[0].title}</h1>
      <p className="main-description">{content[0].description}</p>
      <img 
        src={content[0].image}
        alt="Python Learning"
        className="main-image"
      />
      <div className="next-hint">
        <ChevronRight size={20} />
        <span>Press right arrow to continue</span>
      </div>
    </div>
  );

  const renderLastSlide = () => (
    <div className="last-slide">
      <BookOpen size={38} className="slide-icon" />
      <h2>{content[content.length - 1].title}</h2>
      <p>{content[content.length - 1].description}</p>
      <button 
        className="start-exercise-btn"
        onClick={() => navigate('/compiler')}
      >
        Start Exercise (Enter) ▶
      </button>
    </div>
  );

  const renderMiddleSlide = () => (
    <div className="slide-content">
      <div className="header-content">
        <div className="header-content-title">
         <BookOpen size={24} className="slide-icon" />
         <h1 className="slide-title">{content[currentSlide].title}</h1>
        </div>
        <p className="slide-description">{content[currentSlide].description}</p>
      </div>
      <div className="slide-main">
        <div className="editor-section">
          <div className="editor-header">
            <div className="editor-controls">
              <span className="control red"></span>
              <span className="control yellow"></span>
              <span className="control green"></span>
            </div>
            <span className="file-name">main.py</span>
          </div>
          <div className="editor-content">
            {content[currentSlide].code && (
              <pre>
                <code>{content[currentSlide].code}</code>
              </pre>
            )}
          </div>
        </div>
        {content[currentSlide].output && (
          <div className="output-section">
            <div className="output-header">
              <div className="editor-controls">
                <span className="control red"></span>
                <span className="control yellow"></span>
                <span className="control green"></span>
              </div>
              <span className="output-title">Output</span>
            </div>
            <div className="output-content">
              <pre>
                <code>{content[currentSlide].output}</code>
              </pre>
              {content[currentSlide].explanation && (
                <div className="output-explanation">
                  <span className="explanation-arrow">↑</span>
                  <p>{content[currentSlide].explanation}</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
     <Header/>
    <div className="slides-container" tabIndex="0">
      <SlideProgress current={currentSlide} total={content.length} />
      
      <button className="close-button" onClick={handleClose}>
        <X size={24} />
      </button>

      <div className="slide-content">
        {currentSlide === 0 && renderFirstSlide()}
        {currentSlide > 0 && currentSlide < content.length - 1 && renderMiddleSlide()}
        {currentSlide === content.length - 1 && renderLastSlide()}
      </div>

      <SlideControls 
        currentSlide={currentSlide}
        totalSlides={content.length}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </div>
    </>
  );
};

export default CourseSlides;
