import React, { useState } from 'react';
import CourseSection from './CourseSection';
import { IoLogoGithub } from "react-icons/io5";
import { IoPlayCircle, IoBookmark, IoShare, IoList, IoHeart } from "react-icons/io5";
import './CourseLayout.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useNavigate } from "react-router-dom";

const CourseLayout = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [activeTopic, setActiveTopic] = useState(null);
  const navigate = useNavigate();
  
  const courseContent = {
    'Python Basics': {
      topics: [
        {
          name: 'Variables and Data Types.',
          content: ['String Variables', 'Numeric Variables', 'Boolean Variables', 'Type Conversion']
        },
        {
          name: 'Control Flow',
          content: ['If Statements', 'If-Else', 'Nested If', 'Switch Cases']
        },
        {
          name: 'Loops',
          content: ['For Loop', 'While Loop', 'Do While Loop', 'Break & Continue']
        },
        {
          name: 'Functions',
          content: ['Function Definition', 'Parameters', 'Return Values', 'Lambda Functions']
        }
      ]
    },
    'Python Intermediate': {
      topics: [
        {
          name: 'Object Oriented Programming',
          content: ['Classes', 'Objects', 'Inheritance', 'Polymorphism']
        },
        {
          name: 'Error Handling',
          content: ['Try-Except', 'Custom Exceptions', 'Finally Block', 'Raising Exceptions']
        }
      ]
    },
    'Python Advanced': {
      topics: [
        {
          name: 'Decorators',
          content: ['Function Decorators', 'Class Decorators', 'Property Decorators']
        },
        {
          name: 'Generators',
          content: ['Yield Statement', 'Generator Functions', 'Generator Expressions']
        }
      ]
    }
  };

  const handleSectionClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
    setActiveTopic(null);
  };

  const handleTopicClick = (topic) => {
    setActiveTopic(topic);
  };
  const handleNavigation = () => {
    navigate("/course/python-basics");
  };

  return (
    <div className="page_wrapper">
      <Header />
      <div className="course_container">
        <div className="course_left_panel">
          <h2 className="course_heading">Python Course Content</h2>
          {Object.entries(courseContent).map(([section, { topics }]) => (
            <CourseSection
              key={section}
              section={section}
              topics={topics}
              isActive={activeSection === section}
              activeTopic={activeTopic}
              onClick={() => handleSectionClick(section)}
              onTopicClick={handleTopicClick}
            />
          ))}
        </div>
        
        <div className="course_right_panel">
          <div className="course_content_container">
            <div className="course_content_header">
              <div className="course_logo">
                <IoLogoGithub className="python_logo" />
                <span>Python</span>
              </div>
              <h2>{activeTopic?.name || activeSection || 'Python Course'}</h2>
            </div>
            
            <div className="course_content_body">
              {activeTopic ? (
                <ul className="topic_content_list">
                  {activeTopic.content.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              ) : activeSection ? (
                <ul className="topic_content_list">
                  {courseContent[activeSection].topics.map((topic, index) => (
                    <li key={index} onClick={() => handleTopicClick(topic)}>
                      {topic.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="welcome_content">
                  <h3>Welcome to Python Course</h3>
                  <p>Select a section from the left menu to begin learning.</p>
                </div>
              )}
            </div>
            
            <button className="start_lesson_btn" onClick={handleNavigation}>
              Get Started
            </button>
          </div>

          <div className="course_action_buttons">
            <button className="action_btn video_btn">
              <IoPlayCircle />
              <span>Video</span>
            </button>
            <button className="action_btn quiz_btn">
              <IoList />
              <span>Quiz</span>
            </button>
            <button className="action_btn plan_btn">
              <IoBookmark />
              <span>Learning Plan</span>
            </button>
            <button className="action_btn notice_btn">
              <IoHeart />
              <span>Notice</span>
            </button>
            <button className="action_btn share_btn">
              <IoShare />
              <span>Share</span>
            </button>
          </div>

          <div className="course_description">
            <h3>Course Description</h3>
            <p>Master Python programming from basics to advanced concepts with our comprehensive course.</p>
            
            <h3>What You'll Learn</h3>
            <ul>
              <li>Python fundamentals and syntax</li>
              <li>Object-oriented programming</li>
              <li>Data structures and algorithms</li>
              <li>Web development with Python</li>
            </ul>
            
            <h3>Prerequisites</h3>
            <p>No prior programming experience required. Just bring your enthusiasm to learn!</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CourseLayout;
