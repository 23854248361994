import React, { useState } from 'react';
import './Slides.css';

const SlideControls = ({ currentSlide, totalSlides, onPrevious, onNext }) => {
  const [showPrevTooltip, setShowPrevTooltip] = useState(false);
  const [showNextTooltip, setShowNextTooltip] = useState(false);

  return (
    <div className="slide-navigation">
      <button 
        className="nav-arrow nav-arrow-left"
        onClick={onPrevious}
        disabled={currentSlide === 0}
        onMouseEnter={() => setShowPrevTooltip(true)}
        onMouseLeave={() => setShowPrevTooltip(false)}
      >
        ◀
        {showPrevTooltip && currentSlide > 0 && (
          <div className="nav-tooltip nav-tooltip-left">
            Previous Slide
            <div className="tooltip-shortcut">(or the ← key)</div>
          </div>
        )}
      </button>

      <button 
        className="nav-arrow nav-arrow-right"
        onClick={onNext}
        disabled={currentSlide === totalSlides - 1}
        onMouseEnter={() => setShowNextTooltip(true)}
        onMouseLeave={() => setShowNextTooltip(false)}
      >
        ▶
        {showNextTooltip && currentSlide < totalSlides - 1 && (
          <div className="nav-tooltip nav-tooltip-right">
            Next Slide
            <div className="tooltip-shortcut">(or the → key)</div>
          </div>
        )}
      </button>
    </div>
  );
};

export default SlideControls;